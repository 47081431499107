.btn {
    $this: &;
    font-family: $font-family-regular;
    display: block;
    text-align: center;
    width: 100%;
    border: solid .5rem $color-light-blue;
    border-radius: 5rem;
    appearance: none;
    padding: 1.3rem 0;
    color: white;
    outline: none;
    font-size: 1.4rem;
    text-decoration: none;
    cursor: pointer;
    &--primary {
        background: $color-blue;
        &:hover {
            background: $color-mid-blue;
        }
        &:disabled {
            background: lighten($color-mid-blue, 30)
        }
    }
    &--secondary {
        background: white;
        color: $color-blue;

        &.disabled{
            pointer-events: none;
        }
        &:hover {
            background: #E5E5F4;
        }
        &:disabled {
            color: #E5E5F4;
        }
    }
}
