.tooltip {
    display: block !important;
    z-index: 10000;
    box-shadow: $box-shadow;
    background: $color-frost;
    max-width: 41rem;
    padding: 2rem;

    .tooltip-inner {
        background: white;
        color: $color-grey;
        // padding: 2rem;
        background: $color-frost;
        line-height: 1.3;
        overflow-y: auto;
        max-height: 40rem;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: $color-frost;
        z-index: 1;
    }

    &[x-placement^="top"] {
        margin-bottom: 20px;

        .tooltip-arrow {
            border-width: 20px 20px 0 20px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -20px;
            left: calc(50% - 20px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 18px;

        .tooltip-arrow {
            // background-color: red;
            // z-index: 9999;
            border-width: 20px 20px 0 20px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            top: -20px;
            left: calc(50% - 20px);
            margin-top: 0;
            margin-bottom: 0;
            transform: scaleY(-1);
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }

    h3 {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
    }
}
