.flex {
    display: flex;
}

.flex-start {
    align-items: flex-start;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.align-items-center {
    align-items: center;
}

.align-items-left {
    align-items: flex-start;
}

.align-items-right {
    align-items: flex-end;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-end {
    align-items: flex-end;
}

.row-reverse {
    flex-flow: row-reverse;
}



.justify-content-between {
    justify-content: space-between;
}

.justify-content-end {
    justify-content: flex-end;
}
.justify-content-center{
    justify-content: center;
}

.w100 {
    width: 100%;
}

.w50 {
    width: calc(50% - 2rem);
}

.w33 {
    width: 33%;
}
