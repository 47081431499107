// Colors
$color-dark-blue: #06135B;
$color-blue: #000099;
$color-mid-blue: #374A9A;
$color-light-blue: #E5E5F4;
$color-frost: #EDF6FB;
$color-orange: #FF9900;
$color-grey: #3B3B3B;
$color-light-grey: #E3E3E3;
$color-bg-grey: #FCFCFC;
$color-error: #E2001A;
$color-green: #94C11C;

// Shadows
$box-shadow: 0px 0 39px -4px rgba(0, 0, 0, 0.1);
