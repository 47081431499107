
                @import "/app/src/scss/global.scss";   
.CCookieLayer { //sass-lint:disable-line class-name-format
    background: $color-bg-grey;
    box-shadow: .2rem .2rem 3rem rgba(0, 0, 0, .25); //sass-lint:disable-line no-color-literals
    display: flex;
    justify-content: center;
    padding: 1.2rem .5rem;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9000;

    a {
        font-family: $font-family-regular;
        font-size: 1.3rem;
    }

    .cookie-hint-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .cookie-text {
            color: $color-grey;
            font-family: $font-family-regular;
            font-size: 1.3rem;
            padding: .5rem 1.5rem;
            text-align: left;
            width: calc(100% - 15rem);
            @media screen and (max-width:500px) {
                width: 100%;
            }
        }

        .cookie-control {
            align-self: center;
            position: relative;

            button {
                width: 12rem;
            }

            @media screen and (max-width:500px) {
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
    }
}
