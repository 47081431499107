@import "variables";
@import "flex";
@import "font";
@import "typo";
@import "utils";
@import "form";
@import "buttons";
@import "icons";
@import "tooltip";


html {
    font-size: 62.5%;
}

::-moz-selection { background: $color-orange; }
::selection { background: $color-orange; }

* {
    font-display: swap;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    hyphens: auto;
}

body {
    font-family: "enbw", sans-serif;
    overflow-y: scroll;
    font-size: 1.4rem;
    background: $color-bg-grey;
    color: $color-grey;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

#app {
    height: 100%;
}

.inner {
    width: calc(100% - 4rem);
    max-width: 120rem;
    margin: 0 auto;
}
