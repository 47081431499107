.whitespace-nowrap {
    white-space: nowrap;
}

// Spacings

.p-0 {
    padding: 0;
}

.pt-0 {
    padding-top: 0;
}

.pt-1 {
    padding-top: 1rem;
}

.pt-2 {
    padding-top: 2rem;
}

.pl-1  {
    padding-left: 1rem;
}

.pl-2  {
    padding-left: 2rem;
}

.m-0 {
    margin: 0;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mt-8{
    margin-top: 8rem;
}

.mt-12{
    margin-top: 12rem;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.mb-8 {
    margin-bottom: 8rem;
}

.mb-12{
    margin-bottom: 12rem;
}

.ml-1 {
    margin-left: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-4 {
    margin-right: 4rem;
}

.d-inline-block{
    display: inline-block;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.flex-column {
    flex-direction: column;
    &-reverse {
        flex-direction: column-reverse;
    }
}

.relative {
    position: relative;
}
