/* eslint no-use-before-define: 0 */  // --> OFF

// ICONS
@font-face {
    font-display: fallback;
    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    src:  url("~@/assets/fonts/icon/icomoon.eot?4til7u");
    src:  url("~@/assets/fonts/icon/icomoon.eot?4til7u#iefix") format("embedded-opentype"), //sass-lint:disable-line no-duplicate-properties property-sort-order
    url("~@/assets/fonts/icon/icomoon.ttf?4til7u") format("truetype"),
    url("~@/assets/fonts/icon/icomoon.woff?4til7u") format("woff"),
    url("~@/assets/fonts/icon/icomoon.svg?4til7u#icomoon") format("svg");
}

// EQUIPMENT
@font-face {
    font-display: fallback;
    font-family: "icomoon-equipment";
    font-style: normal;
    font-weight: normal;
    src:  url("~@/assets/fonts/icon/icomoon-equipment.eot?koys8q");
    src:  url("~@/assets/fonts/icon/icomoon-equipment.eot?koys8q#iefix") format("embedded-opentype"), //sass-lint:disable-line no-duplicate-properties property-sort-order
    url("~@/assets/fonts/icon/icomoon-equipment.ttf?koys8q") format("truetype"),
    url("~@/assets/fonts/icon/icomoon-equipment.woff?koys8q") format("woff"),
    url("~@/assets/fonts/icon/icomoon-equipment.svg?koys8q#icomoon-equipment") format("svg");
}

// ENBW DIN Pro Light
@font-face {
    font-display: fallback;
    font-family: "enbw light";
    font-style: normal;
    font-weight: normal;
    src:  url("~@/assets/fonts/enbw/enbwdinwebpro_light.woff") format("woff");
}

// ENBW DIN Pro Regular
@font-face {
    font-display: fallback;
    font-family: "enbw";
    font-style: normal;
    font-weight: normal;
    src:  url("~@/assets/fonts/enbw/enbwdinwebpro.woff") format("woff");
}

// ENBW DIN Pro Medium
@font-face {
    font-display: fallback;
    font-family: "enbw medium";
    font-style: normal;
    font-weight: normal;
    src:  url("~@/assets/fonts/enbw/enbwdinwebpro_medium.woff") format("woff");
}

// ENBW DIN Pro Bold
@font-face {
    font-display: fallback;
    font-family: "enbw bold";
    font-style: normal;
    font-weight: normal;
    src:  url("~@/assets/fonts/enbw/enbwdinwebpro_bold.woff") format("woff");
}
