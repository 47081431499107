.icon {
    $this: &;
    font-family: "icomoon";
    margin-right: .5rem;
    font-size: 1.4rem;

    &--alert::before { content: "\e904"; }
    &--check::before { content: "\e92d"; }
    &--checkCircle::before { content: "\e92b"; }
    &--chevrons-left::before { content: "\e933"; }
    &--download::before { content: "\e959"; }
    &--info::before { content: "\e983"; }
    &--map::before { content: "\e994"; }
    &--minus::before { content: "\e9a1"; }
    &--pen::before { content: "\e95b"; }
    &--plus::before { content: "\e9be"; }
    &--user::before { content: "\ea02"; }
    &--x::before { content: "\ea12"; }
    &--arrow::before { content: "\e92e"; }
    &--menu::before { content: "\e998"; }
    &--phone::before { content: "\e9b8"; }
    &--mail::before { content: "\e992"; }
}
