$font-family-light: "enbw light", sans-serif;
$font-family-regular: "enbw", sans-serif;
$font-family-medium: "enbw medium", sans-serif;
$font-family-bold: "enbw bold", sans-serif;

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    font-weight: normal;
    font-family: $font-family-medium;
    color: $color-grey;
}

h1, .h1 {
    font-size: 3.2rem;
    line-height: 1.2;
}

h2, .h2 {
    font-size: 2.8rem;
}

h3, .h3 {
    font-size: 2.4rem;
}

h4, .h4 {
    font-size: 1.8rem;
}

p, a {
    font-family: $font-family-light;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $color-grey;
}

a {
    color: $color-blue;
    text-decoration: none;
    cursor: pointer;
    &.stepBack {
        display: flex;
        flex-direction: row;
        .icon {
            color: $color-orange;
            font-size: 1.4rem;
            margin-right: .4rem;
        }
    }
    &.edit {
        display: flex;
        flex-direction: row;
        .icon {
            color: $color-orange;
            font-size: 1.4rem;
            margin-right: .4rem;
        }
    }
}

.error {
    color: $color-error;
    a {
        font-size: inherit;
    }
}

.small {
    font-size: 1.4rem;
    line-height: 1.8rem;
}

.smaller {
    font-size: 1.2rem;
    line-height: 1.8rem;
    a {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
}

.fzRegular {
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.text-align-center {
    text-align: center;
}

.font-family-light {
    font-family: $font-family-light;
}

.font-family-regular {
    font-family: $font-family-regular;
}

.font-family-medium {
    font-family: $font-family-medium;
}

.font-family-bold {
    font-family: $font-family-bold;
}

// Font Colors
.fcOrange {
    color: $color-orange;
}

.fcLightGrey  {
    color: lighten($color-grey, 50);
}
