input {
    background: rgb(237,246,251);
    border-style: solid;
    border-width: 0 0 .4rem;
    border-color: $color-light-blue;
    padding: 1.3rem 2rem 1rem;
    width: 100%;
    outline: none;
    font-size: 1.8rem;
    font-family: $font-family-regular;
    color: $color-grey;
    &:focus {
        border-color: $color-blue;
    }
    &.error {
        border-color: $color-error;
    }
    &.disabled {
        color: lighten($color-grey, 50);
    }

    &[type="checkbox"] {
        //left: .2rem;
        opacity: 0;
        //position: absolute;
        //top: .1rem;
        width: 0;

        + label {
            font-family: $font-family-regular;
            word-break: break-word;
            cursor: pointer;
            font-size: 1.6rem;
            line-height: 2.8rem;
            padding-left: 3.8rem;
            position: relative;
            //sass-lint:disable no-transition-all
            transition: all .3s;
            //sass-lint:enable no-transition-all
            &::before {
                background-color: $color-frost;
                border: solid .2rem $color-frost;
                content: "";
                display: inline-block;
                height: 2.5rem;
                left: 0;
                top: .1rem;
                position: absolute;
                transition: border-color .5s;
                width: 2.5rem;
            }
            &:focus::before {
                border-bottom: solid .4rem $color-blue;
            }
        }

        &:disabled{
            + label {
                cursor: not-allowed;
                &::before {
                    background: $color-light-grey;
                    border-color: $color-light-grey;
                }
            }
        }

        &:checked {
            + label {

                &::before {
                    background: white;
                    border-color: $color-blue;
                }

                &::after {
                    // @todo find a better way to extend
                    font-family: "icomoon", sans-serif;
                    content: "\e92d";
                    color: $color-blue;
                    font-size: 2.6rem;
                    height: 1.9rem;
                    left: .2rem;
                    line-height: .5;
                    position: absolute;
                    top: 1rem;
                    width: 1.9rem;
                }
            }
        }
    }
}

input.error {
    color: $color-grey;
}

span.error {
    font-size: 1.2rem;
    margin-top: .5rem;
}

.formElement {
    position: relative;
    span.eye {
        &::after {
            color: $color-blue;
            position: absolute;
            right: 1rem;
            top: 1rem;
            font-size: 2rem;
            font-family: "icomoon";
            content: "\e960";
        }
    }
    &.eye-off {
        span.eye {
            &::after {
                content: "\e95f";
            }
        }
    }

    label {
        opacity: 0;
        display: none;
    }

    input:focus + label, .filled + label {
        opacity: 1;
        display: block;
        position: absolute;
        top: -.6rem;
        left: 1rem;
        font-size: 1.2rem;
        color: $color-blue;
    }
}


.radio-container {
    display: flex;
    align-items: center;
    padding-top: .6rem;
    position: relative;
    padding-left: 3.5rem;
    font-size: 1.8rem;
    font-family: $font-family-regular;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 2.5rem;
        width: 2.5rem;
        background-color: $color-frost;
        border-radius: 50%;
        border: solid .2rem $color-frost;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        border-color: $color-blue;
    }

    /* When the radio button is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: white;
        border-color: $color-blue;
        &::before {
            content: "";
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 50%;
            background: $color-blue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

}
